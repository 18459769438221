import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import HeroText from "../components/heroText"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy Policy" />
    <HeroText
      statement="Privacy Policy"
      caption="Last updated August 22, 2018"
    ></HeroText>
    <div style={{ padding: "1rem" }}>
      <h2>THE SERVICE AND ASSOCIATED SOFTWARE</h2>
      <p>We Beyond Attendance (Pty) Ltd (“Beyond Attendance,” “BA,” “we”, “our” or “us") consider the privacy of our visitors to be extremely important. We are committed to protecting your personal information and your right to privacy. In this Privacy Policy (the “Policy”), we describe how we collect, use, and disclose information that we obtain through the platforms accessible at www.beyondattendance.com and app.beyondattendance.com, as well as the services available via these platforms and any software or mobile application that we make available to provide access to the platforms and services (collectively, the “Services”). The Services are owned and operated by Beyond Attendance, and are used by Beyond Attendances’ organizational customers (our “Customers”) to record, modify and maintain their contractual and non-contractual employee (“Employee”) attendance records for business and non-business related activities.</p>
      <p>By accessing <a href="https://www.beyondattendance.com">https://www.beyondattendance.com</a> and app.beyondattendance.com or using any of our Services (including by downloading our mobile application or any software, or logging into the platforms), as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”) you agree “Consent’ that we will collect and handle personal information as described in this Policy. Your use of our Services, and any dispute over privacy, is subject to this Policy and our Terms of Use including its applicable limitations on damages and the resolution of disputes. The Terms of Use are incorporated by reference into this Policy.</p>
      <p>In addition, this Policy does not govern our Customers’ use or disclosure of Employee information; each Customer is required to post a privacy policy that provides notice to, and if required obtains consent from, their employees to the collection, use and disclosure of information by that Customer, as well as by Beyond Attendance via the Services. We encourage employees to review the privacy policy of any website they visit, or any organization with which they engage online.</p>
      <p>We may collect personal information directly from Customers and their Employees, from third parties, and automatically through the Services that you provide to us when creating an account or accepting an association with Beyond Attendance, expressing an interest in obtaining information about us or our products and services, when using features and functions on the Site or otherwise contacting us.</p>
      <p>Information we collect that identifies or is identifiable to a person is referred to in this Policy as “personal information.” The personal information that we collect depends on the context of your interactions with us and the Site, the choices you make and the products and features you use.</p>
      <p>All personal information that you provide to us must be true, complete and accurate, and you must notify us of any changes to such personal information.</p>
      <p>If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us.</p>

      <h2>INFORMATION WE COLLECT DIRECTLY FROM CUSTOMERS</h2>
      <p>We collect information directly from our Customers about those Customers and about their Employees including:</p>

      <ul>
        <li><b>Registration.</b> To register for the Services, Customers must provide their email address (which will also be their log-in), first and last name, and organization name.</li>
        <li><b>Credentials:</b> We collect passwords and similar security information used for authentication and account access.</li>
        <li><b>Payment Details:</b> We collect Customer credit or debit card information and billing and shipping address to process subscription and other payments.</li>
        <li><b>Employee Lists:</b> In addition, Customers can input and manage Employee lists (including name, contact information, demographic information and other details) and other individual information.</li>
      </ul>

      <h2>INFORMATION WE COLLECT FROM OR ABOUT EMPLOYEES</h2>
      <p>Our Customers collect information directly from their Employees (related to business activities), as well as other information, such as name, contact information, demographic information, credentials for authentication and account access and other information, Employees submit to a Customer, via agreements between the Customer and Employee that Beyond Attendance is not privy to.</p>

      <h2>INFORMATION WE COLLECT FROM THIRD PARTIES</h2>
      <p>If our Customers have opted in or requested that we do so, we may also engage third parties to enhance our product offering. For example, we may provide employee numbers of Employees to a third party and ask that they process this data in a third-party payroll system. We do this to provide Customers with additional services to better facilitate day to day business operations. We won’t sell Customer or Employee lists to third parties; however, the third parties we engage to enhance our product offering may maintain a copy of the lists in their database for their internal purposes.</p>

      <h2>INFORMATION WE COLLECT AUTOMATICALLY</h2>
      <p>We may automatically collect the following information about your use of the Services through cookies, web beacons, log files, and other technologies: domain name; browser type and operating system; pages viewed, links clicked and other activities via the Services; IP address; the length of time using our Services; access time; device ID or other identifier; location and language information. We may combine this information with other information that we have collected, including, where applicable, personal information. Please see the “Cookies and Other Tracking Mechanisms” section below for more information. This information is primarily needed to maintain the security and operation of our Site, and for our internal analytics and reporting purposes.</p>

      <h2>HOW WE USE YOUR INFORMATION</h2>
      <p>We use personal information collected via our Site for a variety of purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests in order to enter into or perform a contract with you, with your consent (“Consent”), and/or for compliance with our legal obligations (“Legal Reasons”).</p>

      <ul>
        <li><b>Customers</b> generally have access to the Employee information we collect and maintain on their behalf, the use and disclosure of such information by our Customers is subject to their respective privacy policies, not this one. We are not liable for our Customers use of Employee information.</li>
        <li><b>To tailor the content and information</b> that we send or display, to offer location customization, and personalized help and instructions, and to otherwise personalize experiences using the Services.</li>
        <li><b>For marketing and promotional purposes.</b> For example, we may send our Customers news and newsletters, special offers, and promotions or to otherwise contact Customers about products or information we think may interest them. We may also use the information we collect to assist us in advertising our Services on third party websites. We do not send promotional emails to Employees, except for, on behalf of or under the direction of our Customers.</li>
        <li><b>For research</b> to better understand how users’ access and use our Services, both on an aggregated and individualized basis, in order to improve our Services and respond to user desires and preferences, other research and analytical purposes.</li>
        <li><b>For Customer advice and recommendations.</b> To aggregate and analyse Employee information across Customers, in order to provide our Customers with recommendations and other information about how to most effectively reach individual Employees as well as segments of Employees. For example, we may provide recommendations to a Customer about how to effectively configure and manage an Employee (e.g. best time of day to configure work rules) based on information that we have gathered. However, we will not share Customer-identifying or Customer-specific information about one Customer with other Customers, without consent.</li>
        <li><b>To send administrative information</b> related to your account, our business purposes, and/or for legal reasons. We may use personal information to send product, service and new feature information and/or information about changes to our terms, conditions and policies.</li>
        <li><b>To post testimonials</b> with your Consent. Prior to posting or publishing a testimonial, we will obtain your consent to use your name and testimonial.</li>
        <li><b>To request feedback</b> for our Business Purposes and/or with your Consent. We may use your information to request feedback and to contact you about your use of our Site.</li>
        <li><b>To protect our Site</b> for Business Purposes and/or Legal Reasons.  We may use your information as part of our efforts to keep our Site safe and secure (for example, for fraud monitoring and prevention).</li>
        <li><b>To promote safety, integrity and security</b> we use the information we have to verify accounts and activity, combat harmful conduct, detect and prevent spam and other bad experiences, maintain the integrity of our Site and Services, and promote safety and security.</li>
        <li><b>To enforce our terms, conditions and policies</b> for our business purposes and as legally required.</li>
        <li><b>For Vital Interests and Legal Rights</b> we may disclose your information where we believe is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.</li>
        <li><b>To respond to legal requests and prevent harm</b> as legally required. If we receive a subpoena or other legal request (including in response to public authorities to meet national security or law enforcement requirements) we may need to inspect the data we hold to determine how to respond and we may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order or legal process.</li>
        <li><b>For other Business Purposes</b> we may use your information for other Business Purposes, such as data analysis, identifying usage trends and to evaluate and improve our Site, products, services and your experience.</li>
      </ul>

      <h2>HOW WE SHARE YOUR INFORMATION</h2>
      <p>We may share the information we collect via the Services, including personal information, as follows:</p>

      <ul>
        <li><b>Affiliates.</b> We may disclose the information we collect from you to our affiliates or subsidiaries, however, if we do so, their use and disclosure of your personally identifiable information will be subject to this Policy.</li>
        <li><b>Third Parties.</b> We will not share Customer or Employee information with third parties other than those set forth in this section, without the relevant Customer’s consent.</li>
        <li><b>Service Providers.</b> We may disclose the information we collect from you to third party vendors, service providers, contractors or agents who perform functions on our behalf.</li>
        <li><b>List Enhancement.</b> If you opt in, we may provide your Employee lists to third parties so that they can be processed in third party applications such as payroll systems. These third parties may maintain a copy of the Employee lists in their database for their internal purposes.</li>
        <li><b>Joint Marketing Partners.</b> We may also partner with third parties to make certain programs or offers available to you. If you elect to participate in such programs we may share some of your Customer information with these third parties.</li>
        <li><b>Business Transfers.</b> If we are acquired by or merged with another business, if substantially all of our assets are transferred to another business, or as part of a bankruptcy proceeding, we may transfer the information we have collected from you to the other business.</li>
        <li><b>In Response to Legal Processes,</b> we may disclose the information we collect from you in order to comply with the law, a judicial proceeding, court order or other legal process, such as in response to a court order or a subpoena.</li>
        <li><b>To Protect Us and Others,</b> we may disclose the information we collect from you where we believe it is necessary to investigate, prevent or take action regarding illegal activities, suspected fraud, situations involving potential threats to the safety of any person, violations of our Terms of Use or this Policy, or as evidence in litigation in which Beyond Attendance is involved.</li>
      </ul>

      <h2>COOKIES AND OTHER TRACKING MECHANISMS</h2>
      <p>We and our third-party service providers use cookies and other tracking mechanisms to track information about the use of our Services by Customers and Employees. We may combine this information with other personal information we collect (and our third-party service providers may do so on our behalf). Currently, our systems do not recognize browser “do-not-track” requests.</p>
      <p>Cookies are alphanumeric identifiers that we transfer to your computer’s hard drive through your web browser for record-keeping purposes. Some cookies allow us to make it easier for you to navigate our Services, while others are used to enable a faster log-in process or to allow us to track activities on our Services. Most web browsers automatically accept cookies, but if you prefer, you can edit your browser options to block them in the future. The Help portion of the toolbar on most browsers will tell you how to prevent your computer from accepting new cookies, how to have the browser notify you when you receive a new cookie, or how to disable cookies altogether. Certain features of the Services will not function if you disable cookies.</p>
      <p>Clear GIFs are tiny graphics with a unique identifier, similar in function to cookies. In contrast to cookies, which are stored on your computer’s hard drive, clear GIFs are embedded invisibly on web pages. We may use clear GIFs (a.k.a. web beacons, web bugs or pixel tags), in connection with our Services to, among other things, track activities on the platforms and Services, help us manage content, and compile statistics about usage of the Services. We and our third-party service providers also use clear GIFs in HTML e-mails to our Customers, to help us track e-mail response rates, identify when our e-mails are viewed, and track whether our e-mails are forwarded.</p>

      <h2>THIRD PARTY ANALYTICS</h2>
      <p>We use automated devices and applications on the Services, such as Google Analytics and Google Maps to evaluate usage of our Services and, our mobile application. We also may use other analytic means to evaluate our Services. We use these tools to help us improve our Services, performance and user experiences. These entities may use cookies and other tracking technologies to perform their services.</p>

      <h2>THIRD-PARTY SITES</h2>
      <p>Our Services may contain links to, or appear on third-party websites. Any access to and use of such third-party websites is not governed by this Policy, but instead is governed by the privacy policies of those third-party websites, and we are not responsible for the information practices of such third-party websites.</p>

      <h2>SECURITY OF PERSONAL INFORMATION</h2>
      <p>Beyond Attendance (Pty) Ltd will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information. We have implemented appropriate technical and organizational security measures designed to protect any personal information we process from loss, misuse, and unauthorized access, disclosure, alteration, and destruction.</p>
      <p>However, please also remember that we cannot guarantee that the internet itself is 100% secure. Although we will do our best to protect your personal information, transmission of personal information to and from our Site is at your own risk. You should only access the services within a secure environment. You should take steps to protect against unauthorized access to your information (including Employee information), password, mobile device and computer by, among other things, signing off after using a shared computer, choosing a robust password that nobody else knows or can easily guess and keeping your log-in and password private. We are not responsible for any lost, stolen or compromised passwords, or for any activity on your account via unauthorized password activity.</p>

      <h2>EU USERS</h2>
      <p>We may process Employees’ personal data as defined by and subject to any national law of an EU member state adopted pursuant to Regulation (EU) 2016/679 (the “GDPR”). When we provide the Services to Our Customers, we process personal data as a “processor” (as defined under the GDPR. Our Customers are the “controllers” (as such term as defined in the GDPR) of Employees’ Personal Data, and We will only process Employees’ Personal Data as instructed by our Customers and pursuant to our written agreements.</p>
      <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>

      <h2>ACCESS TO PERSONAL INFORMATION</h2>
      <p>Customers may modify their registration information by emailing us at info@beyondattendance.com or via their account. Please note that copies of information that you have updated, modified or deleted will remain in our systems.</p>

      <h2>DATA RETENTION</h2>
      <p>We will only keep your personal information for as long as it is necessary for the purposes set out in this privacy policy, unless a longer retention period is required or permitted by law (such as tax, accounting or other legal requirements). When we have no ongoing legitimate business need to process your personal information, we will either delete or anonymize it, or, if this is not possible (for example, because your personal information has been stored in backup archives), then we will securely store your personal information and isolate it from any further processing until deletion is possible.</p>

      <h2>INFORMATION TRANSFERRED INTERNATIONALLY</h2>
      <p>Our servers are located in Europe. If you are accessing our Site from outside of Europe, please be aware that your information may be transferred to, stored, and processed by us in our facilities in South Africa. Your information, including Personal Data, may be transferred to and maintained outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>

      <h2>CHANGES TO THIS POLICY</h2>
      <p>This Policy is current as of the last updated set forth above. We may change this Policy from time to time, so please be sure to check back periodically. We will post any changes to this Policy, at <a href="https://www.beyondattendance.com">https://www.beyondattendance.com</a>. If we make any changes to this Policy that materially affect our practices with regard to the personal information we have previously collected, we will endeavour to provide notice in advance of such change by highlighting the change on our website, or by emailing Customers or notifying them when they log in to the Services.</p>

      <h2>CONTACT US</h2>
      <p>If you have questions about the privacy aspects of our Services or would like to offer praise or make a complaint, please contact us at <a href="mailto:support@beyondattendance.com?Subject=Support">support@beyondattendance.com</a>.</p>


    </div>
  </Layout>
)

export default PrivacyPolicyPage
